import { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { FiTrash } from 'react-icons/fi'
import ElMarketplaceDisplay, {
  ElMarketplaceDisplayForm
} from './ElMarketplaceDisplay'
import { HowItWorks, TopDeals, SplitBlock, CtaBlock, Faqs } from './ElHomepage'
import ElDashboard from './ElDashboard'
import { FormWrapper } from '/components/theme/default/base'
import { StandardInput } from '/components/admin/page/page_builder/form-inputs/Standard'
import { HtmlInput } from '/components/admin/page/page_builder/form-inputs/Html'
import { MediaInputPB } from '/components/admin/page/page_builder/form-inputs/Media'
import { BooleanInput } from '/components/admin/page/page_builder/form-inputs/Boolean'
import { ColorPicker } from '/components/admin/page/page_builder/form-inputs/ColorPicker'

export const FaqForm = ({
  title,
  description,
  questions,
  setCurrentComponent
}) => {
  const addQ = () => {
    setCurrentComponent((prev) => {
      const temp = { ...prev }
      if (!temp.props.questions) {
        temp.props.questions = []
      }
      temp.props.questions.push({ question: '', answer: '' })
      return temp
    })
  }
  const removeQ = (i) => {
    setCurrentComponent((prev) => {
      const temp = { ...prev }
      const qs = [...temp.props.questions]
      qs.splice(i, 1)
      temp.props.questions = qs
      return temp
    })
  }
  return (
    <FormWrapper>
      <StandardInput
        value={title}
        label="Title"
        name="title"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={description}
        label="Description"
        name="description"
        setCurrentComponent={setCurrentComponent}
      />
      {(questions || []).map((faq, i) => (
        <QRow key={i}>
          <StandardInput
            value={faq?.question || ''}
            label={
              <QTitle>
                Question
                <CloseButton onClick={() => removeQ(i)}>
                  <FiTrash />
                </CloseButton>
              </QTitle>
            }
            name="question"
            onChange={(e) => {
              setCurrentComponent((prev) => {
                const temp = { ...prev }
                temp.props.questions[i].question = e.target.value
                return temp
              })
            }}
          />
          <HtmlInput
            value={faq?.answer || ''}
            label="Answer"
            name="answer"
            onChange={(content) => {
              setCurrentComponent((prev) => {
                const temp = { ...prev }
                temp.props.questions[i].answer = content
                return temp
              })
            }}
          />
        </QRow>
      ))}
      <button onClick={addQ}>Add FAQ</button>
    </FormWrapper>
  )
}

const SplitBlockForm = ({
  title,
  description,
  image,
  reverse,
  bg_color,

  cta_text,
  cta_url,

  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <StandardInput
        value={title}
        label="Title"
        name="title"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={description}
        label="Description"
        name="description"
        setCurrentComponent={setCurrentComponent}
      />
      <MediaInputPB
        image={image}
        label="Image"
        name="image"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={cta_text}
        name="cta_text"
        label="CTA Text"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={cta_url}
        name="cta_url"
        label="CTA Url"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <BooleanInput
        value={reverse}
        name="reverse"
        label="Reverse"
        setCurrentComponent={setCurrentComponent}
      />
      <ColorPicker
        value={bg_color}
        label="Background Color"
        name="bg_color"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}

const HowItWorksForm = ({
  title,
  card_1_title,
  card_1_description,
  card_2_title,
  card_2_description,
  card_3_title,
  card_3_description,
  cta_url,
  cta_text,

  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <StandardInput
        value={title}
        label="Title"
        name="title"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={card_1_title}
        label="Card 1 Title"
        name="card_1_title"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={card_1_description}
        label="Card 1 Description"
        name="card_1_description"
        setCurrentComponent={setCurrentComponent}
      />

      <StandardInput
        value={card_2_title}
        label="Card 2 Title"
        name="card_2_title"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={card_2_description}
        label="Card 2 Description"
        name="card_2_description"
        setCurrentComponent={setCurrentComponent}
      />

      <StandardInput
        value={card_3_title}
        label="Care 3 Title"
        name="card_3_title"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={card_3_description}
        label="Card 3 Description"
        name="card_3_description"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={cta_text}
        name="cta_text"
        label="CTA Text"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={cta_url}
        name="cta_url"
        label="CTA Url"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}

const TopDealsForm = ({ title, subtitle, setCurrentComponent }) => {
  return (
    <FormWrapper>
      <StandardInput
        value={title}
        name="title"
        label="Title"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={subtitle}
        name="subtitle"
        label="Subtitle"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}

const CtaBlockForm = ({
  title,
  subtitle,
  cta_text,
  cta_url,
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <StandardInput
        value={title}
        name="title"
        label="Title"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={subtitle}
        name="subtitle"
        label="Subtitle"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={cta_text}
        name="cta_text"
        label="CTA Text"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={cta_url}
        name="cta_url"
        label="CTA Url"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}

export const THEME = {
  marketplace: {
    component: ElMarketplaceDisplay,
    form: ElMarketplaceDisplayForm,
    label: 'Earthlink Marketplace Grid',
    type: ['parent'],
    new: {
      component: 'marketplace',
      props: {}
    }
  },
  faq: {
    component: Faqs,
    form: FaqForm,
    label: 'FAQs',
    type: ['parent'],
    new: {
      component: 'faq',
      label: 'FAQ',
      props: {}
    }
  },
  side_image: {
    component: SplitBlock,
    form: SplitBlockForm,
    label: 'Side Image',
    type: ['parent'],
    new: {
      component: 'side_image',
      label: 'Side Image',
      props: {}
    }
  },
  how_it_works: {
    component: HowItWorks,
    form: HowItWorksForm,
    label: 'How it Works',
    type: ['parent'],
    new: {
      component: 'how_it_works',
      label: 'How it Works',
      props: {}
    }
  },
  top_deals: {
    component: TopDeals,
    form: TopDealsForm,
    label: 'Top Deals',
    type: ['parent'],
    new: {
      component: 'top_deals',
      label: 'Top Deals',
      props: {}
    }
  },
  cta_block: {
    component: CtaBlock,
    form: CtaBlockForm,
    label: 'Cta Block',
    type: ['parent'],
    new: {
      component: 'cta_block',
      label: 'CTA Block',
      props: {}
    }
  },
  dashboard: {
    component: ElDashboard,
    form: null,
    label: 'Earthlink Dashboard',
    type: ['parent'],
    new: {
      component: 'dashboard',
      label: 'Dashboard',
      props: {}
    }
  }
}

export const tw = css`
  --tw-bg-opacity: 1;
  background-color: rgb(87 88 92 / var(--tw-bg-opacity));

  a {
    text-decoration: inherit;
  }

  svg {
    display: block;
    vertical-align: middle;
  }

  @media (min-width: 1200px) {
    .xl-inline-block {
      display: inline-block;
    }
  }

  .fill-elnk-orange-300 {
    fill: #f78d20;
  }

  .w-5 {
    width: 1.25rem;
  }

  .h-5 {
    height: 1.25rem;
  }

  .mr-1 {
    margin-right: 0.25rem;
  }

  .hover-text-elnk-blue-600 {
    &:hover {
      color: #337ab7;
    }
  }

  menu,
  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .p-1 {
    padding: 0.25rem;
  }

  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .text-16 {
    font-size: 1rem;
  }
  .el-hidden {
    /* display: none; */
  }

  .font-semibold {
    font-weight: 600;
  }

  .text-18 {
    font-size: 1.125rem;
  }

  .pt-0 {
    padding-top: 0;
  }

  .pb-30 {
    padding-bottom: 1.875rem;
  }
  .px-30 {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-center {
    justify-content: center;
  }

  .items-center {
    align-items: center;
  }

  .fill-elnk-black-600 {
    fill: #444;
  }
  .w-5 {
    width: 1.25rem;
  }
  .mt-6 {
    margin-top: 1.5rem;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .sm-flex-nowrap {
    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }
  }

  .flex {
    display: flex;
  }

  .items-center {
    align-items: center;
  }

  .font-Montserrat {
    font-family: Montserrat, sans-serif;
  }

  .w-full {
    width: 100%;
  }

  .transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .rotate-0,
  .rotate-1 {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .rotate-0 {
    --tw-rotate: 0deg;
  }
  .w-6 {
    width: 1.5rem;
  }
  .h-6 {
    height: 1.5rem;
  }
  .block {
    display: block;
  }

  .underline {
    text-decoration-line: underline;
  }

  .text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .text-16 {
    font-size: 1rem;
  }

  .inline-block {
    display: inline-block;
  }

  .pb-px {
    padding-bottom: 1px;
  }
  .inline-block {
    display: inline-block;
  }

  .bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .rounded-full {
    border-radius: 9999px;
  }

  .w-9 {
    width: 2.25rem;
  }

  .h-9 {
    height: 2.25rem;
  }
  .inline-flex {
    display: inline-flex;
  }

  .mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  @media (min-width: 767px) {
    .sm-pt-desktop {
      padding-top: 3.75rem;
    }
  }

  @media (min-width: 767px) {
    .sm-pb-desktop {
      padding-bottom: 3.75rem;
    }
  }

  @media (min-width: 767px) {
    .sm-px-15 {
      padding-left: 0.938rem;
      padding-right: 0.938rem;
    }
  }

  @media (min-width: 960px) {
    .smd-block {
      display: block;
    }
  }

  @media (min-width: 960px) {
    .smd-w-auto {
      width: auto;
    }
  }

  @media (min-width: 960px) {
    .smd-hidden {
      display: none;
    }
  }
`

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 767px) {
    max-width: 800px;
  }

  ${(props) =>
    props.flex &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `}
`

const PrimaryColorStyle = css`
  color: ${(props) => props.theme.colors.primary};
  font-weight: inherit;
`

export const PrimaryColor = styled.span`
  ${PrimaryColorStyle}
`

export const UnderlineStyle = css`
  text-decoration: none;
  position: relative;
  font-style: normal;
  font-weight: inherit;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 10px;
    width: 100%;
    height: 10px;
    background: ${(props) => props.theme.colors.primary};
    opacity: 0.7;
  }
`

export const Underline = styled.span`
  ${UnderlineStyle}
`

export const H1 = styled(motion.h1)`
  font-weight: 800 !important;
  letter-spacing: -0.05em;
  font-size: 3.5rem;
  z-index: 5;
  margin: 0;
  padding: 0;

  ${(props) => props.theme.media.tablet`
    font-size: 3rem;
  `}

  em {
    ${UnderlineStyle}
  }

  strong {
    ${PrimaryColorStyle}
  }

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}
`

export const H2 = styled.h2`
  font-weight: 700 !important;
  letter-spacing: -0.05em;
  font-size: 32px;
  color: ${(props) => props.theme.colors.secondary};
  margin: 0;
  padding: 0;
`

export const H3 = styled.h3`
  font-weight: 700 !important;
  letter-spacing: -0.05em;
  font-size: 24px;
  color: ${(props) => props.theme.colors.tertiary};
  margin: 0;
  padding: 0;
`

export const HeroSub = styled(motion.div)`
  font-size: 1.2rem;
  max-width: 450px;
  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}

  em {
    ${UnderlineStyle}
  }

  strong {
    ${PrimaryColorStyle}
  }
`

const QRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  gap: 10px;
`

const QTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`

const CloseButton = styled.button`
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 20px;
  width: 20px;

  svg {
    height: 15px;
    width: 15px;
  }
`
